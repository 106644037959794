import React, { useEffect, useState } from "react";
import { SearchOutlined, FileAddOutlined } from "@ant-design/icons";
import { Table, Input, Button, Popconfirm, Space, Tag, Modal } from "antd";
import {
  del,
  getAll,
  getKaynaByIlacRef,
  save,
} from "../../infrastructure/ttposwebservice";
import newIlac from "../../infrastructure/field_sets/newIlac";
import IlacForm from "./ilac_form";
import KaynakForm from "./kaynak_form";

const IlacListesi = () => {
  const [data, setData] = useState([]);

  const [kaynakList, setKaynakList] = useState([]);
  const [ilacId, setIlacId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [fields, setFields] = useState({});
  const [kaynakFields, setKaynakFields] = useState({});
  const [open, setOpen] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [record, setRecord] = useState({});

  const [searchText, setSearchText] = useState("");

  let [filteredData] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);

    getAll("ilac").then((result) => {
      setLoading(false);

      filteredData = result.filter((value) => {
        return value.ilacAdi.toLowerCase().includes(searchText.toLowerCase());
      });

      console.log(searchText);

      setData(filteredData);
    });
  };

  const loadKaynak = async () => {
    setLoading(true);
    setKaynakFields([
      {
        name: ["id"],
        value: 0,
      },
      {
        name: ["bilgi"],
        value: "",
      },
    ]);

    getKaynaByIlacRef(ilacId).then((result) => {
      setLoading(false);
      setKaynakList(result);
    });
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const handleDelete = async (value) => {
    await del("ilac", value);
    await loadData();
  };

  const handleKaynakDelete = async (value) => {
    await del("kaynak", value);
    await loadKaynak();
  };

  const addNew = async () => {
    setFields(newIlac);
    setOpen(1);
  };

  const kaynaklar = async (record) => {
    setLoading(true);

    setOpen(2);
    console.log(record);
    setIlacId(record.id);
    setKaynakFields([
      {
        name: ["id"],
        value: 0,
      },
      {
        name: ["bilgi"],
        value: "",
      },
    ]);
    getKaynaByIlacRef(record.id).then((result) => {
      setLoading(false);
      setKaynakList(result);
    });
  };

  const edit = async (record) => {
    setFields([
      {
        name: ["id"],
        value: record.id,
      },
      {
        name: ["ilacAdi"],
        value: record.ilacAdi,
      },
      {
        name: ["ilacSinifi"],
        value: record.ilacSinifi,
      },
      {
        name: ["farmakodinamik"],
        value: record.farmakodinamik,
      },
      {
        name: ["farmakokinetik"],
        value: record.farmakokinetik,
      },
      {
        name: ["etkiMekanizmasi"],
        value: record.etkiMekanizmasi,
      },
      {
        name: ["endikasyon"],
        value: record.endikasyon,
      },
      {
        name: ["ilacinSaklanmasi"],
        value: record.ilacinSaklanmasi,
      },
      {
        name: ["yetiskinHazirlik"],
        value: record.yetiskinHazirlik,
      },
      {
        name: ["cocukHazirlik"],
        value: record.cocukHazirlik,
      },
      {
        name: ["yetiskinDoz"],
        value: record.yetiskinDoz,
      },
      {
        name: ["cocukDoz"],
        value: record.cocukDoz,
      },
      {
        name: ["etkilesim"],
        value: record.etkilesim,
      },
      {
        name: ["gecimlilik"],
        value: record.gecimlilik,
      },
      {
        name: ["yaklasimlar"],
        value: record.yaklasimlar,
      },
      {
        name: ["majorYanEtki"],
        value: record.majorYanEtki,
      },
      {
        name: ["minorYanEtki"],
        value: record.minorYanEtki,
      },
    ]);

    console.log(fields);

    setOpen(1);
  };

  const handleOk = async (values) => {
    console.log(values);
    if (values.ilacAdi === undefined) {
      return;
    }

    if (values.ilacSinifi === undefined) {
      return;
    }

    setConfirmLoading(true);
    var newIlac = await save("ilac", values);
    await loadData();
    setConfirmLoading(false);
    setOpen(0);
  };

  const handleCancel = (e) => {
    setOpen(0);
  };

  const handleKaynakOk = async (values) => {
    console.log(values);
    values.ilac = { id: ilacId };
    values.id = 0;
    console.log(values);
    if (values.bilgi === undefined) {
      return;
    }

    setConfirmLoading(true);
    var newKaynak = await save("kaynak", values);
    await loadKaynak();

    setKaynakFields([
      {
        name: ["id"],
        value: 0,
      },
      {
        name: ["bilgi"],
        value: "",
      },
    ]);
    setConfirmLoading(false);
  };

  const columns = [
    {
      title: "İlaç Adı",
      dataIndex: "ilacAdi",
      align: "center",
      sorter: (a, b) => a.ilacAdi.localeCompare(b.ilacAdi),
      sortOrder: sortedInfo.columnKey === "ilacAdi" && sortedInfo.order,
    },
    {
      title: "İlaç Sınıfı",
      dataIndex: "ilacSinifi",
      align: "center",
      sorter: (a, b) => a.ilacSinifi.localeCompare(b.ilacSinifi),
      sortOrder: sortedInfo.columnKey === "ilacSinifi" && sortedInfo.order,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary">
                Sil
              </Button>
            </Popconfirm>
            <Button onClick={() => edit(record)} type="primary">
              Güncelle
            </Button>

            <Button onClick={() => kaynaklar(record)} type="dashed">
              Kaynaklar
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  const kaynakColumns = [
    {
      title: "Kaynak",
      dataIndex: "bilgi",
      align: "center",
      sorter: (a, b) => a.bilgi.localeCompare(b.bilgi),
      sortOrder: sortedInfo.columnKey === "bilgi" && sortedInfo.order,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleKaynakDelete(record)}
            >
              <Button danger type="primary">
                Sil
              </Button>
            </Popconfirm>
          </Space>
        ) : null;
      },
    },
  ];

  const globalSearch = async (e) => {
    await loadData();
    console.log(searchText);
    filteredData = await data.filter((value) => {
      return value.ilacAdi.toLowerCase().includes(searchText.toLowerCase());
    });

    console.log(searchText);

    setData(filteredData);
  };

  return (
    <div>
      <Modal
        title="İlaç Kartı"
        open={open == 1}
        footer={null}
        width={1000}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <IlacForm
          fields={fields}
          onCancel={handleCancel}
          onOK={handleOk}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      </Modal>
      <Modal
        title="Kaynaklar"
        open={open == 2}
        footer={null}
        width={1000}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <KaynakForm
          onCancel={handleCancel}
          onOK={handleKaynakOk}
          fields={kaynakFields}
          onChange={(newFields) => {
            setKaynakFields(newFields);
          }}
        ></KaynakForm>
        <Table
          columns={kaynakColumns}
          dataSource={kaynakList}
          bordered
          loading={loading}
          onChange={handleChange}
          size="middle"
          rowKey="id"
        />
      </Modal>

      <Space>
        <Button onClick={addNew} type="primary" icon={<FileAddOutlined />}>
          Yeni İlaç
        </Button>

        <Input
          style={{ width: 200, margin: 10 }}
          placeholder="Filtre"
          type="text"
          allowClear
          value={searchText}
          onChange={handleInputChange}
        />
        <Button onClick={globalSearch} type="primary" icon={<SearchOutlined />}>
          Listele
        </Button>
        <Tag color="orange">({data.length})</Tag>
      </Space>

      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        onChange={handleChange}
        size="middle"
        rowKey="id"
      />
    </div>
  );
};

export default IlacListesi;
