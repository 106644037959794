import { Form, Tabs, Input, Space, Button } from "antd";

const SemptomKaynakForm = ({ onChange, fields, onCancel, onOK }) => {
  return (
    <div>
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        fields={fields}
        onFinish={onOK}
        onCancel={onCancel}
        onFieldsChange={(changedFields, allFields) => {
          onChange(allFields);
        }}
      >
        <Form.Item name="id"></Form.Item>
        <Form.Item
          name="bilgi"
          label="Kaynak Açıklama"
          rules={[
            {
              required: true,
              message: "Kaynak açıklama alanı zorunludur!",
            },
          ]}
        >
          <Input maxLength={500} />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit">
            Kaydet
          </Button>
          <Button type="primary" danger onClick={onCancel}>
            Vazgeç
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default SemptomKaynakForm;
