const newIlac = [
  {
    name: ["id"],
    value: 0,
  },
  {
    name: ["ilacAdi"],
    value: "",
  },
  {
    name: ["ilacSinifi"],
    value: "",
  },
  {
    name: ["farmakodinamik"],
    value: "",
  },
  {
    name: ["farmakokinetik"],
    value: "",
  },
  {
    name: ["etkiMekanizmasi"],
    value: "",
  },
  {
    name: ["endikasyon"],
    value: "",
  },
  {
    name: ["ilacinSaklanmasi"],
    value: "",
  },
  {
    name: ["yetiskinHazirlik"],
    value: "",
  },
  {
    name: ["cocukHazirlik"],
    value: "",
  },
  {
    name: ["yetiskinDoz"],
    value: "",
  },
  {
    name: ["cocukDoz"],
    value: "",
  },
  {
    name: ["etkilesim"],
    value: "",
  },
  {
    name: ["gecimlilik"],
    value: "",
  },
  {
    name: ["yaklasimlar"],
    value: "",
  },
  {
    name: ["majorYanEtki"],
    value: "",
  },
  {
    name: ["minorYanEtki"],
    value: "",
  },
];

export default newIlac;
