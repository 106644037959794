import { Col, Row, Radio, Card, Typography } from "antd";
import DashBoarsCard from "../common/dash_board_card";
import { CreditCardOutlined } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";

const HomePage = () => {
  const onChange = (value) => {};
  const { Title, Text } = Typography;

  return <div className="layout-content"></div>;
};

export default HomePage;
