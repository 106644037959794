import React from "react";
import { Form, Tabs, Input, Space, Button, Row, Col } from "antd";
import TextArea from "antd/es/input/TextArea";

const IlacForm = ({ onChange, fields, onCancel, onOK }) => {
  const Tab1 = () => {
    return (
      <div>
        <Form.Item
          name="ilacAdi"
          label="İlac Adı"
          rules={[
            {
              required: true,
              message: "İlaç adı alanı zorunludur!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="ilacSinifi"
          label="İlaç Sınıfı"
          rules={[
            {
              required: true,
              message: "İlaç Sınıfı alanı zorunludur!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="farmakodinamik" label="Farmakodinamiği">
          <TextArea rows={6} />
        </Form.Item>

        <Form.Item name="farmakokinetik" label="Farmakokinetiği">
          <TextArea rows={6} />
        </Form.Item>
      </div>
    );
  };

  const Tab2 = () => {
    return (
      <div>
        <Form.Item name="etkiMekanizmasi" label="Etki Mekanizması">
          <TextArea rows={6} />
        </Form.Item>
        <Form.Item name="endikasyon" label="Endikasyonu">
          <TextArea rows={6} />
        </Form.Item>
        <Form.Item name="ilacinSaklanmasi" label="İlacın Saklanması">
          <TextArea rows={6} />
        </Form.Item>
      </div>
    );
  };

  const Tab3 = () => {
    return (
      <div>
        <Form.Item name="yetiskinHazirlik" label="Yetişkin Hazırlık">
          <TextArea rows={6} />
        </Form.Item>
        <Form.Item name="yetiskinDoz" label="Yetişkin Doz">
          <TextArea rows={6} />
        </Form.Item>
      </div>
    );
  };

  const Tab4 = () => {
    return (
      <div>
        <Form.Item name="cocukHazirlik" label="Çocuk Hazırlık">
          <TextArea rows={6} />
        </Form.Item>

        <Form.Item name="cocukDoz" label="Çocuk Doz">
          <TextArea rows={6} />
        </Form.Item>
      </div>
    );
  };

  const Tab5 = () => {
    return (
      <div>
        <Form.Item name="etkilesim" label="Etkileşim">
          <TextArea rows={6} />
        </Form.Item>
        <Form.Item name="gecimlilik" label="Geçimlilik">
          <TextArea rows={6} />
        </Form.Item>
        <Form.Item name="yaklasimlar" label="Yaklaşımlar">
          <TextArea rows={6} />
        </Form.Item>
      </div>
    );
  };

  const Tab6 = () => {
    return (
      <div>
        <Form.Item name="majorYanEtki" label="Major Yan Etki">
          <TextArea rows={6} />
        </Form.Item>
        <Form.Item name="minorYanEtki" label="Minor Yan Etki">
          <TextArea rows={6} />
        </Form.Item>
      </div>
    );
  };

  const items = [
    {
      key: "1",
      label: "Genel Bilgiler",
      children: Tab1(),
    },
    {
      key: "2",
      label: "Etki-Endikasyon-Saklanma",
      children: Tab2(),
    },
    {
      key: "3",
      label: "Yetişkin",
      children: Tab3(),
    },
    {
      key: "4",
      label: "Çocuk",
      children: Tab4(),
    },
    {
      key: "5",
      label: "Etkileşim-Geçimlilik-Yaklaşımlar",
      children: Tab5(),
    },
    {
      key: "6",
      label: "Yan Etkiler",
      children: Tab6(),
    },
  ];

  const onTabChange = (key) => {
    console.log(key);
  };
  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      name="ilac_form"
      layout="horizontal"
      fields={fields}
      onFinish={onOK}
      onCancel={onCancel}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item name="id"></Form.Item>
      <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />

      <Space>
        <Button type="primary" htmlType="submit">
          Kaydet
        </Button>
        <Button type="primary" danger onClick={onCancel}>
          Vazgeç
        </Button>
      </Space>
    </Form>
  );
};
export default IlacForm;
