import React, { useEffect, useState } from "react";
import { SearchOutlined, FileAddOutlined } from "@ant-design/icons";
import { Table, Input, Button, Popconfirm, Space, Tag, Modal } from "antd";
import {
  del,
  getAll,
  getKaynakBySemptomRef,
  save,
} from "../../infrastructure/ttposwebservice";
import SemptomForm from "./semptom_form";
import newSemptom from "../../infrastructure/field_sets/newSemptom";
import SemptomKaynakForm from "./semptom_kaynak_form";

const SemptomListesi = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [semptomId, setSemptomId] = useState(0);
  const [kaynakFields, setKaynakFields] = useState({});
  const [kaynakList, setKaynakList] = useState([]);

  const [fields, setFields] = useState({});
  const [open, setOpen] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [record, setRecord] = useState({});

  const [searchText, setSearchText] = useState("");

  let [filteredData] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);

    getAll("semptom").then((result) => {
      setLoading(false);

      filteredData = result.filter((value) => {
        return value.tanim.toLowerCase().includes(searchText.toLowerCase());
      });

      console.log(searchText);

      setData(filteredData);
    });
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };
  const handleDelete = async (value) => {
    await del("semptom", value);
    await loadData();
  };

  const addNew = async () => {
    setFields(newSemptom);
    setOpen(1);
  };

  const handleKaynakOk = async (values) => {
    console.log(values);
    values.semptom = { id: semptomId };
    values.id = 0;
    console.log(values);
    if (values.bilgi === undefined) {
      return;
    }

    setConfirmLoading(true);
    var newKaynak = await save("semptomkaynak", values);
    await loadKaynak();

    setKaynakFields([
      {
        name: ["id"],
        value: 0,
      },
      {
        name: ["bilgi"],
        value: "",
      },
    ]);
    setConfirmLoading(false);
  };

  const edit = async (record) => {
    setFields([
      {
        name: ["id"],
        value: record.id,
      },
      {
        name: ["adi"],
        value: record.adi,
      },
      {
        name: ["tanim"],
        value: record.tanim,
      },
      {
        name: ["belirtiler"],
        value: record.belirtiler,
      },
      {
        name: ["tanilamaOlcekleri"],
        value: record.tanilamaOlcekleri,
      },
      {
        name: ["hemsirelikGirisimleri"],
        value: record.hemsirelikGirisimleri,
      },
      {
        name: ["hastaEgitimi"],
        value: record.hastaEgitimi,
      },
    ]);

    console.log(fields);

    setOpen(1);
  };

  const handleOk = async (values) => {
    console.log(values);
    if (values.tanim === undefined) {
      return;
    }

    setConfirmLoading(true);
    var newSemptom = await save("semptom", values);
    await loadData();
    setConfirmLoading(false);
    setOpen(0);
  };

  const handleCancel = (e) => {
    setOpen(0);
  };

  const handleKaynakDelete = async (value) => {
    console.log(value);
    await del("semptomkaynak", value);
    await loadKaynak();
  };

  const kaynaklar = async (record) => {
    setLoading(true);

    setOpen(2);
    console.log(record);
    setSemptomId(record.id);
    setKaynakFields([
      {
        name: ["id"],
        value: 0,
      },
      {
        name: ["bilgi"],
        value: "",
      },
    ]);
    getKaynakBySemptomRef(record.id).then((result) => {
      setLoading(false);
      setKaynakList(result);
    });
  };

  const loadKaynak = async () => {
    setLoading(true);
    setKaynakFields([
      {
        name: ["id"],
        value: 0,
      },
      {
        name: ["bilgi"],
        value: "",
      },
    ]);

    getKaynakBySemptomRef(semptomId).then((result) => {
      setLoading(false);
      setKaynakList(result);
    });
  };

  const columns = [
    {
      title: "Adı",
      dataIndex: "adi",
      align: "center",
      sorter: (a, b) => a.tanim.localeCompare(b.adi),
      sortOrder: sortedInfo.columnKey === "adi" && sortedInfo.order,
    },
    {
      title: "Tanım",
      dataIndex: "tanim",
      align: "center",
      sorter: (a, b) => a.tanim.localeCompare(b.tanim),
      sortOrder: sortedInfo.columnKey === "tanim" && sortedInfo.order,
    },
    {
      title: "Belirtiler",
      dataIndex: "belirtiler",
      align: "center",
      sorter: (a, b) => a.belirtiler.localeCompare(b.belirtiler),
      sortOrder: sortedInfo.columnKey === "belirtiler" && sortedInfo.order,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary">
                Sil
              </Button>
            </Popconfirm>
            <Button onClick={() => edit(record)} type="primary">
              Güncelle
            </Button>
            <Button onClick={() => kaynaklar(record)} type="dashed">
              Kaynaklar
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  const kaynakColumns = [
    {
      title: "Kaynak",
      dataIndex: "bilgi",
      align: "center",
      sorter: (a, b) => a.bilgi.localeCompare(b.bilgi),
      sortOrder: sortedInfo.columnKey === "bilgi" && sortedInfo.order,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleKaynakDelete(record)}
            >
              <Button danger type="primary">
                Sil
              </Button>
            </Popconfirm>
          </Space>
        ) : null;
      },
    },
  ];

  const globalSearch = async (e) => {
    await loadData();
    console.log(searchText);
    filteredData = await data.filter((value) => {
      return value.tanim.toLowerCase().includes(searchText.toLowerCase());
    });

    console.log(searchText);

    setData(filteredData);
  };

  return (
    <div>
      <Modal
        title="Semptom Kartı"
        open={open == 1}
        footer={null}
        width={1000}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <SemptomForm
          fields={fields}
          onCancel={handleCancel}
          onOK={handleOk}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      </Modal>

      <Modal
        title="Kaynaklar"
        open={open == 2}
        footer={null}
        width={1000}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <SemptomKaynakForm
          onCancel={handleCancel}
          onOK={handleKaynakOk}
          fields={kaynakFields}
          onChange={(newFields) => {
            setKaynakFields(newFields);
          }}
        ></SemptomKaynakForm>
        <Table
          columns={kaynakColumns}
          dataSource={kaynakList}
          bordered
          loading={loading}
          onChange={handleChange}
          size="middle"
          rowKey="id"
        />
      </Modal>

      <Space>
        <Button onClick={addNew} type="primary" icon={<FileAddOutlined />}>
          Yeni Semptom
        </Button>

        <Input
          style={{ width: 200, margin: 10 }}
          placeholder="Filtre"
          type="text"
          allowClear
          value={searchText}
          onChange={handleInputChange}
        />
        <Button onClick={globalSearch} type="primary" icon={<SearchOutlined />}>
          Listele
        </Button>
        <Tag color="orange">({data.length})</Tag>
      </Space>

      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        onChange={handleChange}
        size="middle"
        rowKey="id"
      />
    </div>
  );
};

export default SemptomListesi;
