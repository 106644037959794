const newSemptom = [
  {
    name: ["id"],
    value: 0,
  },
  {
    name: ["adi"],
    value: "",
  },
  {
    name: ["tanim"],
    value: "",
  },
  {
    name: ["belirtiler"],
    value: "",
  },
  {
    name: ["tanilamaOlcekleri"],
    value: "",
  },
  {
    name: ["hemsirelikGirisimleri"],
    value: "",
  },
  {
    name: ["hastaEgitimi"],
    value: "",
  },
];

export default newSemptom;
