import React from "react";
import { Form, Tabs, Input, Space, Button, Row, Col } from "antd";
import TextArea from "antd/es/input/TextArea";

const SemptomForm = ({ onChange, fields, onCancel, onOK }) => {
  const Tab1 = () => {
    return (
      <div>
        <Form.Item
          name="adi"
          label="Adı"
          rules={[
            {
              required: true,
              message: "Adı alanı zorunludur!",
            },
          ]}
        >
          <Input maxLength={500} />
        </Form.Item>
        <Form.Item
          name="tanim"
          label="Tanım"
          rules={[
            {
              required: true,
              message: "Tanım alanı zorunludur!",
            },
          ]}
        >
          <Input maxLength={500} />
        </Form.Item>
        <Form.Item name="belirtiler" label="Belirtiler">
          <TextArea rows={6} maxLength={500} />
        </Form.Item>
      </div>
    );
  };

  const Tab2 = () => {
    return (
      <div>
        <Form.Item name="tanilamaOlcekleri" label="Tanımlama Ölçekleri">
          <TextArea rows={6} maxLength={500} />
        </Form.Item>
        <Form.Item name="hemsirelikGirisimleri" label="Hemşirelik Girişimleri">
          <TextArea rows={6} maxLength={500} />
        </Form.Item>
        <Form.Item name="hastaEgitimi" label="Hasta Eğitimi">
          <TextArea rows={6} maxLength={500} />
        </Form.Item>
      </div>
    );
  };

  const items = [
    {
      key: "1",
      label: "Genel Tanımlar",
      children: Tab1(),
    },
    {
      key: "2",
      label: "Diğer Bilgiler",
      children: Tab2(),
    },
  ];

  const onTabChange = (key) => {
    console.log(key);
  };
  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      name="semptom_form"
      layout="horizontal"
      fields={fields}
      onFinish={onOK}
      onCancel={onCancel}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item name="id"></Form.Item>
      <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />

      <Space>
        <Button type="primary" htmlType="submit">
          Kaydet
        </Button>
        <Button type="primary" danger onClick={onCancel}>
          Vazgeç
        </Button>
      </Space>
    </Form>
  );
};
export default SemptomForm;
